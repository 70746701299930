import { Injector } from "@angular/core";
import { Validators } from "@angular/forms";

import { ActionButton } from "../../../../components/buttons/gloss-button/actionButton";
import { Balances } from "../../../../models/enum/balance.enum";
import { InputTypes } from "../../../../models/enum/inputTypes.enum";
import { PreferenceType } from "../../../../models/enum/preferenceType";
import { TransactionStatusEnum } from "../../../../models/enum/transactionType";
import { UserLocationCurrencyEnum } from "../../../../models/enum/user-location.enum";
import { FormInterface } from "../../../../models/interfaces/form.interface";
import { GlossInputOptions } from "../../../../models/types/general-types";
import { TransactionData } from "../../../../models/types/transaction.types";
import { BaseForm } from "../../../../shared/form/BaseForm";
import { HelperNumericInput } from "../../../../shared/utils/helper-numeric-input";
import { BookBalance } from "../../../../shared/utils/helper.book/balance";
import {
  actionButtonOptions,
  amountInputOptions,
  defaultInputOptions,
} from "../accounts-add-edit/component.options";

export class BalanceForm extends BaseForm implements FormInterface {
  data: TransactionData;
  injector: Injector;
  submitButton: () => void;

  balanceInputOptions: GlossInputOptions;
  timeInputOptions: GlossInputOptions;
  descriptionInputOptions: GlossInputOptions;
  saveButtonOptions: ActionButton;
  selectedBalance: string;

  balances = [
    {
      label: this.i18nService.t("openingBalance"),
      value: Balances.opening,
      selected: true,
      labelType: "text",
    },
    {
      label: this.i18nService.t("closingBalance"),
      value: Balances.closing,
      selected: false,
      labelType: "text",
    },
  ];

  constructor(injector: Injector, data: TransactionData, submitButton: () => void) {
    super(injector);
    this.data = data;
    this.injector = injector;
    this.submitButton = submitButton;
    this.initialize();
  }

  async initialize() {
    this.selectedBalance = Balances.opening;
    await this.setCurrency();

    this.title = this.i18nService.t("enterAccountData");

    this.formGroup = this.formBuilder.group({
      currency: [this.currency, Validators.required],
      balance: [null, [HelperNumericInput.isNumberValidator, Validators.required]],
      date: [this.dateFormat.getDateStringFromStamp(new Date().getTime()), Validators.required],
      time: [null],
      description: [null],
      type: this.selectedBalance,
      transactionStatus: TransactionStatusEnum.manual_balance,
    });

    this.balanceInputOptions = {
      ...amountInputOptions,
      isRequired: true,
      label: this.i18nService.t("balance"),
      onInput: (event: Event) => this.onInputNumeric(event, "balance"),
      inputBlurred: () => this.clearDefaultValue("balance"),
    };

    this.timeInputOptions = {
      ...defaultInputOptions,
      label: this.i18nService.t("time"),
      placeholder: "00:00:00",
      isRequired: false,
      type: InputTypes.time,
      onInput: (event: Event) => this.onInput(event, "time"),
    };

    this.descriptionInputOptions = {
      ...defaultInputOptions,
      label: this.i18nService.t("description"),
      placeholder: this.i18nService.t("description"),
      onInput: (event: Event) => this.onInput(event, "description"),
      isRequired: false,
    };

    this.saveButtonOptions = new ActionButton({
      ...actionButtonOptions,
      onClick: this.submitButton.bind(this),
    });
  }

  async setCurrency() {
    /** set currency to account's currency */
    this.currency = this.data?.accountView?.currency;

    /** if for some reason account doesn't have currency then set it on location */
    if (!this.currency) {
      const locationKey = PreferenceType.userLocation;
      const userLocation = (await this.preferenceService.get(
        locationKey
      )) as keyof typeof UserLocationCurrencyEnum;
      this.currency = UserLocationCurrencyEnum[userLocation];
    }
  }

  selectBalance(value: string) {
    this.selectedBalance = value;
    this.formGroup.controls.type.setValue(value);
  }

  resetForm() {
    this.saveButtonOptions.enableButton(true);
    this.loading = false;
  }

  async submit() {
    const isDateTouched = this.formGroup.controls.date.touched;
    !isDateTouched && this.onDateChange("");

    this.loading = true;

    if (!this.formGroup.valid) {
      this.resetForm();
      return;
    }

    const balanceForm = this.formGroup.value;
    const balance = new BookBalance(this.data.accountView, balanceForm, this.injector);
    const result = await balance.process();

    if (result) {
      this.data.closeDialogue();
      this.loading = false;
    } else {
      this.resetForm();
    }
  }
}
