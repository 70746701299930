import "./dash-transaction-table.scss";
import { SelectionModel } from "@angular/cdk/collections";
import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  QueryList,
  SimpleChanges,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { MatCheckbox } from "@angular/material/checkbox";
import { MatPaginator } from "@angular/material/paginator";
import { MatSort } from "@angular/material/sort";
import { MatTableDataSource } from "@angular/material/table";
import { Router } from "@angular/router";
import { AgGridAngular } from "ag-grid-angular";
import { ColDef, GetGroupRowAggParams, SizeColumnsToFitGridStrategy } from "ag-grid-community";
import { LicenseManager, RowModelType, SortModelItem } from "ag-grid-enterprise";
import { DeviceDetectorService } from "ngx-device-detector";
import { Subject, takeUntil } from "rxjs";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { PlatformUtilsService } from "@bitwarden/common/abstractions/platformUtils.service";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { NoRowsOverlayComponentOfDashTable } from "@bitwarden/web-vault/app/components/cell-renderers/no-rows-overlay-component-of-dash-table/no-rows-overlay-component-of-dash-table.component";
import { transactionColumnDefinitions } from "@bitwarden/web-vault/app/components/dash-transaction-table/ag-grid-column-definitions";
import { getRightClickButtons } from "@bitwarden/web-vault/app/components/dash-transaction-table/ag-grid-right-click-buttons";
import { DashboardParameters } from "@bitwarden/web-vault/app/components/dashboard-selector/dashboard-selector.component";
import { Book } from "@bitwarden/web-vault/app/models/data/blobby/book.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { GlossDate } from "@bitwarden/web-vault/app/models/data/shared/gloss-date";
import { ConfirmationEnum } from "@bitwarden/web-vault/app/models/enum/confirmation.enum";
import { TransactionDirection } from "@bitwarden/web-vault/app/models/enum/transactionDirection";
import { TransactionView } from "@bitwarden/web-vault/app/models/view/transaction.view";
import { BalanceGrouping } from "@bitwarden/web-vault/app/services/DataCalculationService/balanceGrouping/balanceGrouping";
import { DataRepositoryService } from "@bitwarden/web-vault/app/services/DataRepository/data-repository.service";
import { TransactionService } from "@bitwarden/web-vault/app/services/DataService/transaction/transaction.service";
import { ConfirmationDialogService } from "@bitwarden/web-vault/app/services/confirmation/confirmation.service";
import { ColumnStateService } from "@bitwarden/web-vault/app/services/dashboard/columnState-service";
import { DashboardService } from "@bitwarden/web-vault/app/services/dashboard/dashboard-service";
import { DataTransformer } from "@bitwarden/web-vault/app/services/dto/data-transformer";
import { RoleAccessService } from "@bitwarden/web-vault/app/services/permission/role-access.service";
import { SharedService } from "@bitwarden/web-vault/app/shared/sharedAppEvent.service";
import { ExperimentalMode } from "@bitwarden/web-vault/app/shared/utils/feature-flag/experimental-mode";
import { getDailyBalance } from "@bitwarden/web-vault/app/shared/utils/helper-balance";
import { HelperPagination } from "@bitwarden/web-vault/app/shared/utils/helper-pagination";
import { HelperPreference } from "@bitwarden/web-vault/app/shared/utils/helper-preference";
import { LinkTransaction } from "@bitwarden/web-vault/app/shared/utils/helper.transactions/link-transaction";
import { ValidatorResponseTypes } from "@bitwarden/web-vault/app/validators/link.transactions/link-validator";

import { commonDefs, granularityGroupingFormatter } from "../../shared/utils/table/helper.ag-grid";
import { TransactionDateCellRender } from "../cell-renderers/transaction-date-cell-renderer/transaction-date-cell-renderer.component";

import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";

/* Set license */
LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-063300}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Ironfly_Technologies_Limited}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GLOSS_Vault}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GLOSS_Vault}_need_to_be_licensed___{GLOSS_Vault}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{11_July_2025}____[v3]_[01]_MTc1MjE4ODQwMDAwMA==78426cc29fbc2c22bf97224bbd624392"
);

@Component({
  selector: "app-dash-transaction-table",
  templateUrl: "dash-transaction-table.component.html",
  styles: ["dash-transaction-table.scss", "ag-grid.css", "ag-theme-quartz.css"],
})

/*TODO clean this component from old mat-table codes and remove the old mat-table component if not used */
export class DashTransactionTableComponent
  extends LinkTransaction
  implements OnInit, OnChanges, OnDestroy
{
  protected readonly GlossDate = GlossDate;
  protected readonly NoRowsOverlayComponentOfDashTable = NoRowsOverlayComponentOfDashTable;
  private unsubscribe$ = new Subject<void>();
  private paginationHelper = new HelperPagination();

  private isBetaUser = false;

  paginationPageSize = 100;
  scenarioIndex: number;
  selectedTransactions: Transaction[] = [];
  linkButtonEnabled = false;
  autoSizeStrategy: SizeColumnsToFitGridStrategy = {
    type: "fitGridWidth",
    defaultMinWidth: 450,
  };
  columnMenu: "legacy" | "new" = "new";
  mobilCellContextMenu = false;
  rowSelection: "multiple" | "single" | undefined;
  rowModelType: RowModelType = "clientSide";
  cacheBlockSize = 5;
  infiniteInitialRowCount = 0;

  // Column Definitions: Defines the columns to be displayed.
  // For the mobile the grouping is not  enabled yet
  colDefs: ColDef[] = !this.isMobile()
    ? transactionColumnDefinitions
    : transactionColumnDefinitions.map((colDef) => {
        if (colDef.field === "transactionDate") {
          colDef.rowGroup = false;
        }
        return colDef;
      });

  autoGroupColumnDef: ColDef = !this.isMobile()
    ? {
        headerName: "Period",
        field: "transactionDate",
        flex: 1,
        maxWidth: 150,
        ...commonDefs,
        suppressColumnsToolPanel: true,
        cellRenderer: TransactionDateCellRender,
      }
    : null;

  context = { componentParent: this };
  rowClassRules = {
    "ag-row-selected": (params: any) => params.node.selected,
  };

  @Input() transactionsView: Array<TransactionView>;
  @Input() transactions: Array<Transaction>;
  @Input() groupBalances: BalanceGrouping;
  @Input() transactionBalances: Record<string, number>;
  @Input() mockAccounts: Array<Book>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort) sort: MatSort;
  @ViewChildren(MatCheckbox) checkboxes: QueryList<MatCheckbox>;
  @ViewChild("agGrid") agGrid: AgGridAngular;

  gridOptions = {
    suppressAggFuncInHeader: true,
    pagination: true,
    paginationPageSize: this.paginationPageSize,
    paginationAutoPageSize: true,
    groupDefaultExpanded: 0,
  };

  private destroy$ = new Subject<void>();
  dashboardParameters: DashboardParameters;
  protected readonly top = top;
  mouseOnTd = false;
  mouseOnTooltip = false;
  hoveredTransaction: TransactionView;
  showTooltipBoolean = false;
  gridApi: any;
  xPosition = 0;
  yPosition = 0;
  selectedTransactionId: string;
  dataSource: MatTableDataSource<TransactionView>;
  selection: SelectionModel<any>;
  initialised = false;
  linkedId = "";
  baseCurrency = "";
  displayedColumns: string[] = [
    //  "link",
    "account",
    "transactionDate",
    "categories",
    // "classifications",
    "description",
    "out",
    "in",
    // "out_normalized",
    // "in_normalized",
    // "aggregate",
    "balance",
  ];
  showRevaluations = true;
  showEstimates = true;
  isActionExperimental: boolean;
  isHideEstimateExperimental: boolean;
  indexViewMap: Map<string, number> = new Map();
  helpOpen = false;
  prefilledMessage = "I would like to join the early access program…";

  constructor(
    private logService: LogService,
    public i18nService: I18nService,
    private transactionService: TransactionService,
    private dataRepositoryService: DataRepositoryService,
    private platformUtilsService: PlatformUtilsService,
    private router: Router,
    private helperPreference: HelperPreference,
    protected experimentalMode: ExperimentalMode,
    private dashboardService: DashboardService,
    private globalService: GlobalService,
    private roleAccessService: RoleAccessService,
    private sharedService: SharedService,
    private deviceService: DeviceDetectorService,
    private columnStateService: ColumnStateService,
    private confirmationDialogService: ConfirmationDialogService
  ) {
    super();
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  isLinkButtonEnabled() {
    this.linkButtonEnabled = this.linkedTransactions?.length > 1;
    return this.linkButtonEnabled;
  }

  async getAccount(p: any) {
    return "Account";
  }

  getColumnState() {
    return this.columnStateService.getColumnState();
  }

  saveColumnState() {
    return this.columnStateService.saveColumnState(this.gridApi);
  }

  onGridReady(params: any) {
    this.gridApi = params.api;
    const columnState = this.getColumnState();
    if (columnState) {
      this.gridApi.applyColumnState({ state: columnState });
    } else {
      this.gridApi.applyColumnState({
        state: [{ colId: "transactionDate", sort: "desc" }],
      });
    }
    if (this.dashboardService.granularity) {
      this.gridApi.setGridOption("columnDefs", this.colDefs);
    }
    this.rowSelection = this.isMobile() ? "multiple" : "single";
    this.hideActionColumn();

    this.updateData(this.transactionsView);
  }

  sortDataMobile(sortModel: SortModelItem[], data: any[]) {
    const sortPresent = sortModel && sortModel.length > 0;
    if (!sortPresent) {
      return data;
    }
    // do an in memory sort of the data, across all the fields
    const resultOfSort = data.slice();
    resultOfSort.sort(function (a, b) {
      for (let k = 0; k < sortModel.length; k++) {
        const sortColModel = sortModel[k];
        const valueA = a[sortColModel.colId];
        const valueB = b[sortColModel.colId];
        // this filter didn't find a difference, move onto the next one
        if (valueA == valueB) {
          continue;
        }
        const sortDirection = sortColModel.sort === "asc" ? 1 : -1;
        if (valueA > valueB) {
          return sortDirection;
        } else {
          return sortDirection * -1;
        }
      }
      // no filters found a difference
      return 0;
    });
    return resultOfSort;
  }

  updateData(data: any[]) {
    this.gridApi?.setGridOption("rowData", data);

    if (this.gridApi && data.length === 0) {
      this.gridApi?.showNoRowsOverlay();
    } else {
      this.gridApi?.hideOverlay();
    }
  }

  getContextMenuItems = (params: any) => {
    return !params.node.id.includes("row-group") && getRightClickButtons(params, this.isBetaUser);
  };

  async rightClickDeleteTransactions(transactionToDelete: Transaction) {
    const selectedTransactions = this.selectedTransactions.filter(
      (transaction: Transaction) => transaction.id !== transactionToDelete.id
    );
    this.selectedTransactions = [transactionToDelete];
    await this.deleteTransaction();
    this.selectedTransactions = selectedTransactions;
  }

  async onCellClicked(event: any) {
    if (this.isMobile()) {
      if (!this.mobilCellContextMenu) {
        event.node.setSelected(true);
        const params = {
          x: event.event.clientX,
          y: event.event.clientY,
          rowNode: event.node,
          column: event.column,
          value: event.value,
        };
        this.gridApi.showContextMenu(params);
        await this.addSelectTransaction(event.data);
        this.mobilCellContextMenu = true;
      } else {
        this.selectedTransactions = [];
        this.mobilCellContextMenu = false;
      }
    }
  }

  async onCellRightClicked(event: any) {
    event.node.setSelected(true);
    if (this.isMobile()) {
      await this.addSelectTransaction(event.data);
    }
  }

  async addSelectTransaction(view: TransactionView) {
    if (!this.selectedTransactions.some((transaction) => transaction.id === view.id)) {
      await this.checkToggle(view, true);
    } else {
      await this.checkToggle(view, false);
    }
  }

  async ngOnInit() {
    this.initialised = true;
    this.baseCurrency = await this.helperPreference.getBaseCurrency();
    this.isActionExperimental = await this.isActionsExperimental();
    this.isHideEstimateExperimental = await this.isEstimateExperimental();

    if (this.isActionExperimental) {
      this.displayedColumns.push("actions");
    }

    this.hoveredTransaction = this.transactionsView[0];
    this.selectedTransactionId = this.transactionsView[0]?.id;

    this.dashboardService.dashboardConfig$
      .pipe(takeUntil(this.destroy$))
      .subscribe((dashboardParameters) => {
        if (dashboardParameters) {
          this.dashboardParameters = dashboardParameters;
        }
      });

    this.dashboardService.defaultGranularity$
      .pipe(takeUntil(this.destroy$))
      .subscribe((granularity) => {
        granularity = this.dashboardService.granularity;

        const colDefs = this.colDefs.find((colDefs) => colDefs.field === "transactionDate");
        if (!colDefs) {
          return;
        }
        colDefs.keyCreator = granularityGroupingFormatter(granularity);
        this.gridApi && this.gridApi.setGridOption("columnDefs", this.colDefs);
      });

    // Subscribe to observable to update grid options dynamically
    this.dashboardService._scenarioIndex$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((scenarioIndex) => {
        this.scenarioIndex = scenarioIndex;
      });

    this.isBetaUser = this.roleAccessService.isBetaUser();
  }

  getGroupRowAgg(params: GetGroupRowAggParams) {
    const result = {
      institutionName: new Set<string>(),
      accountName: new Set<string>(),
      description: new Set<string>(),
      amountIn: 0,
      amountOut: 0,
      balance: 0,
    };

    params.nodes.forEach((node) => {
      const data = node.group ? node.aggData : node.data;

      result.institutionName.add(data.institutionName);
      result.accountName.add(data.accountName);
      result.description.add(data.description);

      if (data.direction === TransactionDirection.In) {
        result.amountIn += data.aggregateAmount;
      }
      if (data.direction === TransactionDirection.Out) {
        result.amountOut += data.aggregateAmount;
      }
    });

    const sortedNodes = params?.nodes?.sort((a, b) => {
      return a.data.glossDate.date - b.data.glossDate.date;
    });
    result.balance = sortedNodes[sortedNodes.length - 1]?.data?.dailyBalance;

    return {
      institutionName: Array.from(result.institutionName).join(", "),
      accountName: Array.from(result.accountName).join(", "),
      description: Array.from(result.description).join(", "),
      amountIn: result.amountIn,
      amountOut: result.amountOut,
      balance: result.balance,
      symbol: params.context.componentParent.baseCurrency,
    };
  }

  hideActionColumn() {
    this.gridApi.setColumnsVisible(["isSelected"], false);
    if (this.isMobile()) {
      this.gridApi.setColumnsVisible(["isSelected"], false);
    } else {
      this.gridApi.setColumnsVisible(["isSelected"], true);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    // TODO - @sinan@Ronald - Normally groupBalances change was appearing right away before after new changes I had to put it here to make it work
    if (
      changes?.transactionsView?.currentValue.length > 0 ||
      changes.transactionBalances?.currentValue
    ) {
      this.setupDataSource();
      this.updateData(this.transactionsView);
    }
  }

  onMouseEnterTooltip(showTooltip: boolean) {
    this.mouseOnTooltip = true;
    this.showTooltipBox(true);
  }

  onMouseLeaveTooltip(showTooltip: boolean) {
    this.mouseOnTooltip = false;
    setTimeout(() => {
      if (!this.mouseOnTooltip && !this.mouseOnTd) {
        this.showTooltipBox(false);
      }
    });
  }

  handleHelpBoxOpen($event: boolean) {
    this.helpOpen = $event;
  }

  openHelp(event: Event, prefilledMessage: string) {
    this.prefilledMessage = prefilledMessage;
    this.helpOpen = true;
  }

  showTooltipBox(showTooltip: boolean) {
    this.showTooltipBoolean = showTooltip;
    if (!showTooltip) {
      this.yPosition = 0;
      this.xPosition = 0;
    }
  }

  async checkToggle(transactionView: TransactionView, checked: boolean) {
    const isReval = this.isRevaluation(transactionView);
    if (!this.isValidToLink(isReval)) {
      this.check[transactionView.id] = false;
      await this.showToaster(isReval, this.platformUtilsService, this.i18nService);
      //event.source.toggle();
      return;
    }

    const isSelected = checked;
    const linkedViews = this.transactionsView.filter((view, index) => {
      return transactionView.linkedTo.includes(view.id) || view.id === transactionView.id;
    });
    const uniqueIds: Set<string> = new Set();
    const allLinkViews = linkedViews.filter((linkView) => {
      if (!uniqueIds.has(linkView.id)) {
        uniqueIds.add(linkView.id);
        return true;
      }

      return false;
    });
    const linkTransactions = DataTransformer.castToTransactionArray(allLinkViews);
    for (const trans of linkTransactions) {
      if (isSelected) {
        if (trans.id !== transactionView.id) {
          this.check[trans.id] = true;
        }
        this.selectToLink(trans);
      } else {
        if (trans.id !== transactionView.id) {
          this.check[trans.id] = false;
        }
        this.unselectToLink(trans);
      }
    }

    this.isLinkButtonEnabled();

    if (checked) {
      this.selectedTransactions.push(transactionView.baseTransaction);
    } else {
      this.selectedTransactions = this.selectedTransactions.filter(
        (transaction) => transaction.id !== transactionView.baseTransaction.id
      );
    }
  }

  handleEdit(transactionView: TransactionView) {
    this.router.navigate(["/add-edit-transaction"], {
      queryParams: { transactionId: transactionView.id },
    });
  }

  async refreshDataSource() {
    const transactions = await this.dataRepositoryService.getAllTransactions();
    this.dataSource.data = this.transactionsView.map((transactionView) => {
      if (this.linkIds.includes(transactionView.id)) {
        const linkedTransaction = transactions.find(
          (transaction) => transaction.id === transactionView.id
        );
        if (linkedTransaction) {
          return transactionView.update(linkedTransaction);
        } else {
          return transactionView;
        }
      } else {
        return transactionView;
      }
    });

    this.transactionsView = this.dataSource.data;
  }

  async linkConversion() {
    try {
      this.loading = true;
      const isConversion = this.isConversion(this.linkedTransactions);
      if (!this.isValidToLink(isConversion)) {
        await this.showToaster(isConversion, this.platformUtilsService, this.i18nService);
        return;
      }
      await this.saveLink(isConversion);
    } catch (e) {
      this.logService.error(e.message);
    }
  }

  async linkTransfer() {
    try {
      this.loading = true;
      const isTransfer = this.isTransfer(this.linkedTransactions);
      if (!this.isValidToLink(isTransfer)) {
        await this.showToaster(isTransfer, this.platformUtilsService, this.i18nService);
        return;
      }
      await this.saveLink(isTransfer);
    } catch (e) {
      this.logService.error(e.message);
    }
  }

  async unlinkTransactions() {
    try {
      const { unlinkTransactions, newTransactionsView } = await this.removeLinkage(
        this.transactionsView
      );
      this.transactionsView = newTransactionsView;
      await this.save(this.UNLINK_RESPONSE, unlinkTransactions);
      this.loading = false;
    } catch (e) {
      this.logService.error(e.message);
    }
  }

  async resetColumns() {
    this.gridApi.resetColumnState();
  }

  async deleteTransaction() {
    try {
      if (this.selectedTransactions.length === 0) {
        this.globalService.showMessage("info", "", "selectAtLeastOneTransaction");
        return;
      }

      const isValidToDelete = this.validateDelete(this.selectedTransactions);
      if (!this.isRealTransactions(this.selectedTransactions)) {
        await this.showToaster(isValidToDelete, this.platformUtilsService, this.i18nService);
        return;
      }

      const confirm = await this.confirmationDialogService.confirmFor(
        ConfirmationEnum.deletingTransaction
      );

      if (confirm) {
        this.loading = true;
        const areDeleted = await this.transactionService.bulkDelete(this.selectedTransactions);
        if (areDeleted) {
          await this.showToaster(isValidToDelete, this.platformUtilsService, this.i18nService);

          this.transactionsView = this.transactionsView.filter(
            (transactionView) =>
              !this.linkedTransactions.some(
                (linkedTransaction) => linkedTransaction.id === transactionView.id
              )
          );
        } else {
          isValidToDelete.status = "warning";
          isValidToDelete.messageId = "missingTransactionsToDelete";
          await this.showToaster(isValidToDelete, this.platformUtilsService, this.i18nService);
        }
        await this.dashboardService.loadDashboardData();
        this.sharedService.triggerApplyEvent();
        this.resetProps();

        this.loading = false;
      }
    } catch (e) {
      this.logService.error(e.message);
    }
  }

  private async saveLink(response: ValidatorResponseTypes) {
    await this.save(response, this.linkedTransactions);
  }

  private async save(response: ValidatorResponseTypes, transactions: Transaction[]) {
    for (const transaction of transactions) {
      await this.transactionService.update(transaction);
    }

    await this.refreshDataSource();
    await this.showToaster(response, this.platformUtilsService, this.i18nService);
    this.resetProps();
  }

  setCheckBoxIndex() {
    if (this.transactionsView.length) {
      this.transactionsView.forEach((view, index) => {
        this.indexViewMap.set(view.id, index);
        this.check[view.id] = false;
        if (
          this.selectedTransactions.some(
            (transaction) => transaction.id === view.baseTransaction.id
          )
        ) {
          view.isSelected = true;
        }
      });
    }
  }

  private setupDataSource() {
    this.setCheckBoxIndex();
    const transactionsView = getDailyBalance(this.transactionsView, this.transactionBalances);
    if (this.mockAccounts?.length) {
      transactionsView.forEach((transaction) => transaction.setMockAccount(this.mockAccounts));
    }

    // Filter the data based on showRevaluations
    const filteredData = this.filterDataSource(
      transactionsView,
      this.showRevaluations,
      this.showEstimates
    );

    this.dataSource = new MatTableDataSource<TransactionView>(filteredData);
    this.dataSource.paginator = this.paginator;
    //this.dataSource.sortingDataAccessor = this.sortColumns;

    /** Set the default sorting direction for the 'transactionDate' column */
    if (this.sort) {
      this.sort.start = "desc";
      this.sort.active = "transactionDate";
      this.sort.direction = "desc";
      this.dataSource.sort = this.sort;
    }
  }

  private filterDataSource(
    data: TransactionView[],
    showRevaluations: boolean,
    showEstimates: boolean
  ): TransactionView[] {
    if (showRevaluations) {
      // When showRevaluations is true, no filtering is applied based on revalTransaction
      return showEstimates ? data : data.filter((item) => !item.estimateTransaction);
    } else {
      // When showRevaluations is false, show items with revalTransaction as false and showEstimates based on the specified value
      return data.filter(
        (item) => !item.revalTransaction && (showEstimates ? true : !item.estimateTransaction)
      );
    }
  }

  async isActionsExperimental() {
    return await this.experimentalMode.isExperimental("transaction", "action");
  }

  async isEstimateExperimental() {
    return await this.experimentalMode.isExperimental("transaction", "toggleEstimate");
  }

  isMobile() {
    return this.deviceService.isMobile();
  }

  isPaginationEnabled() {
    return this.paginationHelper.isPaginationEnabled();
  }
}
