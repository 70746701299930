import { Component, DoCheck, Injector, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { MatPaginator } from "@angular/material/paginator";
import { MatTableDataSource } from "@angular/material/table";
import { ActivatedRoute, Router } from "@angular/router";
import {
  ColDef,
  SizeColumnsToContentStrategy,
  SizeColumnsToFitGridStrategy,
  SizeColumnsToFitProvidedWidthStrategy,
  // ColumnMenuTab,
} from "ag-grid-community";
import {
  IDatasource,
  IGetRowsParams,
  LicenseManager,
  RowModelType,
  SortModelItem,
} from "ag-grid-enterprise";
import { DeviceDetectorService } from "ngx-device-detector";
import { Subject, takeUntil } from "rxjs";

import { I18nService } from "@bitwarden/common/abstractions/i18n.service";
import { LogService } from "@bitwarden/common/abstractions/log.service";
import { MessagingService } from "@bitwarden/common/abstractions/messaging.service";
import { GlobalService } from "@bitwarden/common/services/global/global.service";
import { ActionButton } from "@bitwarden/web-vault/app/components/buttons/gloss-button/actionButton";
import { AccountBalanceComponent } from "@bitwarden/web-vault/app/gloss/manage/manage-accounts/account-balance/account-balance.component";
import { AccountRegionsComponent } from "@bitwarden/web-vault/app/gloss/manage/manage-accounts/account-regions/account-regions.component";
import { getContextMenu } from "@bitwarden/web-vault/app/gloss/manage/manage-accounts/context-menu";
import { CreationOptionsComponent } from "@bitwarden/web-vault/app/gloss/manage/manage-accounts/creation-options/creation-options.component";
import { InstitutionSelectionComponent } from "@bitwarden/web-vault/app/gloss/manage/manage-accounts/institution-selection/institution-selection.component";
import { TransactionComponent } from "@bitwarden/web-vault/app/gloss/manage/manage-accounts/transaction/transaction.component";
import { manageButtonProperties } from "@bitwarden/web-vault/app/gloss/manage/manage.components";
import { BasiqAuth } from "@bitwarden/web-vault/app/importers/importer.auth.basiq";
import { TransactionBasiqImporter } from "@bitwarden/web-vault/app/importers/transaction-basiq-importer";
import { Connector } from "@bitwarden/web-vault/app/models/data/blobby/connector.data";
import { Institution } from "@bitwarden/web-vault/app/models/data/blobby/institution.data";
import { SourceBook } from "@bitwarden/web-vault/app/models/data/blobby/source-book";
import { SourceTransaction } from "@bitwarden/web-vault/app/models/data/blobby/source-transaction.data";
import { Transaction } from "@bitwarden/web-vault/app/models/data/blobby/transaction.data";
import { ConnectorResponse } from "@bitwarden/web-vault/app/models/data/response/connector.response";
import { GlossDate } from "@bitwarden/web-vault/app/models/data/shared/gloss-date";
import { ConfirmationEnum } from "@bitwarden/web-vault/app/models/enum/confirmation.enum";
import { RoleScope } from "@bitwarden/web-vault/app/models/enum/role-access.enum";
import { SyncIcon } from "@bitwarden/web-vault/app/models/enum/sync.enum";
import { scopeEnum } from "@bitwarden/web-vault/app/models/scope/typeDef";
import { Origin } from "@bitwarden/web-vault/app/models/types/general-types";
import { AccountView } from "@bitwarden/web-vault/app/models/view/account.view";
import { ConnectorService } from "@bitwarden/web-vault/app/services/DataService/connector/connector.service";
import { InstitutionService } from "@bitwarden/web-vault/app/services/DataService/institution/institution.service";
import { PreferenceService } from "@bitwarden/web-vault/app/services/DataService/preference/preference.service";
import { SourceImportService } from "@bitwarden/web-vault/app/services/DataService/source-import/source-import.service";
import { SubscriptionService } from "@bitwarden/web-vault/app/services/DataService/subscription/subscription.service";
import { TransactionService } from "@bitwarden/web-vault/app/services/DataService/transaction/transaction.service";
import { ConfirmationDialogService } from "@bitwarden/web-vault/app/services/confirmation/confirmation.service";
import { SideMenuService } from "@bitwarden/web-vault/app/services/menu/side-menu.service";
import { Permission } from "@bitwarden/web-vault/app/services/permission/permission";
import { BasiqProgressBar } from "@bitwarden/web-vault/app/services/progress-bar/basiq-progress-bar";
import { BasiqAccountSync } from "@bitwarden/web-vault/app/services/syncing/basiq.account.sync";
import { HelperCommon } from "@bitwarden/web-vault/app/shared/utils/helper-common";
import { HelperPagination } from "@bitwarden/web-vault/app/shared/utils/helper-pagination";

import { Book } from "../../../models/data/blobby/book.data";
import { BookService } from "../../../services/DataService/book/book.service";

import { AccountAddEditComponent } from "./accounts-add-edit/account-add-edit.component";

/* Set license */
LicenseManager.setLicenseKey(
  "Using_this_{AG_Grid}_Enterprise_key_{AG-063300}_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_legal@ag-grid.com___For_help_with_changing_this_key_please_contact_info@ag-grid.com___{Ironfly_Technologies_Limited}_is_granted_a_{Single_Application}_Developer_License_for_the_application_{GLOSS_Vault}_only_for_{1}_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_{GLOSS_Vault}_need_to_be_licensed___{GLOSS_Vault}_has_been_granted_a_Deployment_License_Add-on_for_{1}_Production_Environment___This_key_works_with_{AG_Grid}_Enterprise_versions_released_before_{11_July_2025}____[v3]_[01]_MTc1MjE4ODQwMDAwMA==78426cc29fbc2c22bf97224bbd624392"
);

const commonDefs = {
  suppressHeaderMenuButton: true,
  mainMenuItems: [
    "sortAscending",
    "sortDescending",
    "autoSizeThis",
    {
      name: "Autosize All Columns",
      action: (params: any) => {
        if (params.api) {
          params.api.sizeColumnsToFit();
        }
      },
    },
    "columnChooser",
    {
      name: "Reset Columns",
      action: (params: any) => {
        if (params.api) {
          params.api.sizeColumnsToFit();
          params.api.resetColumnState();
        }
      },
    },
  ],
};

@Component({
  selector: "manage-account",
  templateUrl: "manage-account.component.html",
})
export class ManageAccountComponent implements OnInit, OnDestroy, DoCheck {
  protected readonly Origin = Origin;
  protected readonly RoleScope = RoleScope;
  baseCurrency: string;
  autoSizeStrategy:
    | SizeColumnsToFitGridStrategy
    | SizeColumnsToFitProvidedWidthStrategy
    | SizeColumnsToContentStrategy = this.isMobile()
    ? { type: "fitCellContents" }
    : { type: "fitGridWidth", defaultMinWidth: 200 };
  columnMenu = "new" as const;

  // Column Definitions: Defines the columns to be displayed.
  colDefs: ColDef[] = [
    /*
    // TODO @Sinan is betting 5$ that this column will be needed.. Alex, Horace bets otherwise 5$ in 6 months. Today is 20-08-2024 and if not Sinan will remove the functionality too
    {
      headerName: " ",
      field: "isSelected",
      maxWidth: 30,
      cellClass: "_sCheckBox",
      cellRenderer: CheckBoxCellRenderer,
    },*/
    {
      headerName: "Account",
      field: "name",
      valueGetter: (params) => params.data?.name,
      ...commonDefs,
    },
    {
      headerName: "Institution",
      field: "institution",
      valueGetter: (params) => params.data?.institution?.name,
      ...commonDefs,
    },
    {
      headerName: "Account Product",
      field: "type",
      valueGetter: (params) => params.data?.institutionAccountType?.name,
      ...commonDefs,
    },
    {
      headerName: "Source",
      field: "source",
      valueGetter: (params) => params.data?.source,
      ...commonDefs,
    },
    {
      headerName: "Status",
      field: "status",
      valueGetter: (params) => params.data?.status,
      ...commonDefs,
    },
    {
      headerName: "Interest Rate",
      field: "rate",
      valueGetter: (params) => this.getRate(params.data),
      ...commonDefs,
    },
    /* { headerName: "Actions", field: "actions",  cellRenderer: AccountActionButtonsCellRenderer }, */
  ];

  gridOptionsExtended = {
    localeText: {
      noRowsToShow:
        "There are currently no accounts created. Please create new accounts in the system or connect your finance institutions.",
    },
    defaultColDef: {
      comparator: (valueA: any, valueB: any, nodeA: any, nodeB: any, isInverted: any) => {
        const fA = parseFloat(valueA);
        const fB = parseFloat(valueB);
        if (!Number.isNaN(fA) && !Number.isNaN(fB)) {
          return fA - fB;
        } else {
          return valueA.toLowerCase().localeCompare(valueB.toLowerCase());
        }
      },
    },
  };
  context = { componentParent: this };

  private paginationHelper = new HelperPagination();
  rowModelType: RowModelType = this.isPaginationEnabled() ? "clientSide" : "infinite";
  cacheBlockSize = 20;
  infiniteInitialRowCount = 20;
  agGridStyle: string = "height: 500px; " + (this.isMobile() ? "padding-bottom: 70px" : "");

  checkedAccounts: AccountView[] = [];
  paginationPageSize = 25;
  paginationPageSizeSelector = [25, 50, 100];

  syncAllButton = new ActionButton({
    ...manageButtonProperties,
    text: this.i18nService.t("syncAll"),
    onClick: this.syncAllAccounts.bind(this),
    icon: "sync-all",
  });

  addNewButton = new ActionButton({
    ...manageButtonProperties,
    text: this.i18nService.t("addNew"),
    onClick: this.openCreationOptionsModal.bind(this),
  });

  gridOptions = this.isPaginationEnabled()
    ? {
        pagination: this.isPaginationEnabled(),
        paginationPageSize: this.paginationPageSize,
        paginationPageSizeSelector: this.paginationPageSizeSelector,
        ...this.gridOptionsExtended,
      }
    : {
        cacheBlockSize: this.cacheBlockSize,
        infiniteInitialRowCount: this.infiniteInitialRowCount,
        ...this.gridOptionsExtended,
      };

  dataSource: MatTableDataSource<AccountView>;
  @ViewChild(MatPaginator) paginator: MatPaginator;
  displayedColumns: string[] = ["name", "institution", "type", "source", "status", "actions"];
  rowSelection: "single" | "multiple" | undefined = "single";
  gridApi: any;

  private basiqImporter: TransactionBasiqImporter = null;
  private transactionBasiqImporter: TransactionBasiqImporter = null;
  private destroy$ = new Subject<void>();
  private openOptions: string = null;
  private openBalance: string = null;
  localEnv = false;
  sourceBook: SourceBook;
  loading = false;
  inWizard = false;
  spinnerLoader = false;
  accessLoader = true;
  hasAccess = false;
  isInfo = true;
  progressInfoText = "Connecting to Basiq...";
  canAccessPremium: boolean;
  existingAccounts: Book[];
  existingInstitutions: Institution[];
  existingConnector: Connector[];
  booksView: AccountView[];
  actionPromise: Promise<any>;
  accountBalanceRef: MatDialogRef<AccountBalanceComponent>;
  transactionDialogRef: MatDialogRef<TransactionComponent>;
  accountAddRef: MatDialogRef<AccountAddEditComponent>;
  creationOptionsRef: MatDialogRef<CreationOptionsComponent>;
  regionOptionsRef: MatDialogRef<AccountRegionsComponent>;
  institutionsModalRef: MatDialogRef<InstitutionSelectionComponent>;

  sourceBooks: SourceBook[];
  sourceTransactions: SourceTransaction[];
  label: string;
  progressBarPercent = 0;
  hasScope: boolean;

  totalItems = 0;
  protected unsubscribe$ = new Subject<void>();

  constructor(
    private messagingService: MessagingService,
    public dialog: MatDialog,
    private bookService: BookService,
    private transactionService: TransactionService,
    private institutionService: InstitutionService,
    private sourceTransactionService: SourceImportService,
    private globalService: GlobalService,
    private basiqAuth: BasiqAuth,
    private logService: LogService,
    private router: Router,
    private injector: Injector,
    private subscriptionService: SubscriptionService,
    private permission: Permission,
    private route: ActivatedRoute,
    private i18nService: I18nService,
    private connectorService: ConnectorService,
    private deviceService: DeviceDetectorService,
    private helperCommon: HelperCommon,
    private glossMenuService: SideMenuService,
    private preferenceService: PreferenceService,
    private confirmationDialogService: ConfirmationDialogService,
    private basiqProgressBar: BasiqProgressBar
  ) {}

  async ngOnInit() {
    this.baseCurrency = <string>await this.preferenceService.get("baseCurrency");
    this.spinnerLoader = true;
    this.openOptions = this.route.snapshot.paramMap.get("openOptions");
    this.openBalance = this.route.snapshot.paramMap.get("openBalance");

    this.transactionBasiqImporter = this.injector.get(TransactionBasiqImporter);

    this.existingAccounts = await this.bookService.getAll();
    this.existingInstitutions = await this.institutionService.getAll();
    this.booksView = await this.bookService.getBooksView();
    this.dataSource = new MatTableDataSource<AccountView>(this.booksView);
    this.dataSource.paginator = this.paginator;
    this.sourceBooks = await this.bookService.getAllSourceBooks();
    this.sourceTransactions = await this.transactionService.getAllSourceTransactions();
    this.existingConnector = await this.connectorService.getAllConnectors();
    this.hasScope = await this.helperCommon.hasScope(scopeEnum.DATA_AGGREGATOR);
    if (this.openBalance) {
      const firstManuelAccount = this.booksView.find((account) => account.origin === Origin.manual);
      if (firstManuelAccount) {
        this.addBalance(firstManuelAccount);
      } else {
        this.globalService.showMessage(
          "info",
          "addAccount",
          "pleaseCreateAnAccountManuallyToAddBalance"
        );
      }
      this.openBalance = null;
      this.router.navigate(["/manage/account"]).then();
    }
    if (process.env.NODE_ENV === "development") {
      this.localEnv = true;
    }
    this.spinnerLoader = false;
    try {
      this.loading = true;
      const localStartTut = localStorage.getItem("inWizard");
      this.inWizard = localStartTut === "true";
      await this.handleBasiqRedirect();
      this.loading = false;
    } catch (e) {
      this.loading = false;
      this.logService.error(e);
    }
  }

  getRate(accountView: AccountView) {
    const rate = accountView?.institutionAccountType?.interestRates;
    if (rate && rate.length > 0) {
      const maxRate = rate.reduce((max, current) => {
        return current.rate > max ? current.rate : max;
      }, rate[0].rate);
      const minRate = rate.reduce((min, current) => {
        return current.rate < min ? current.rate : min;
      }, rate[0].rate);

      const finalRate = rate.length > 1 ? `${minRate}% - ${maxRate}%` : `${maxRate}%`;
      return `${finalRate} p.a.`;
    } else {
      return "Unknown Account Type";
    }
  }

  onFirstDataRendered(params: any): void {
    params.api.sizeColumnsToFit();
  }

  onGridReady(params: any): void {
    this.gridApi = params.api;
    params.api.applyColumnState({
      state: [{ colId: "name", sort: "asc" }],
    });
    this.autoSizeStrategy = this.isMobile()
      ? { type: "fitCellContents" }
      : { type: "fitGridWidth", defaultMinWidth: 200 };

    this.gridApi = params.api;

    this.hideActionColumn();

    this.loadGridData(this.booksView);
  }

  sortData(sortModel: SortModelItem[], data: any[]) {
    const sortPresent = sortModel && sortModel.length > 0;
    if (!sortPresent) {
      return data;
    }
    // do an in memory sort of the data, across all the fields
    return data.slice().sort((a, b) => {
      for (const { colId, sort } of sortModel) {
        let valueA = a[colId];
        let valueB = b[colId];
        if (colId === "rate") {
          valueA = this.getRate(a);
          valueB = this.getRate(b);
        }
        // this filter didn't find a difference, move onto the next one
        if (valueA == valueB) {
          continue;
        }
        return (valueA > valueB ? 1 : -1) * (sort === "asc" ? 1 : -1);
      }
      // no filters found a difference
      return 0;
    });
  }

  loadGridData(data: any) {
    const dataSourceAG: IDatasource = {
      rowCount: undefined, // behave as infinite scroll
      getRows: async (params: IGetRowsParams) => {
        const sortData = this.sortData(params.sortModel, data);
        // take a slice of the total rows
        const rowsThisPage = sortData.slice(params.startRow, params.endRow);
        // if on or after the last page, work out the last row.
        let lastRow = -1;
        if (data.length <= params.endRow) {
          lastRow = data.length;
        }
        // call the success callback
        params.successCallback(rowsThisPage, lastRow);
      },
    };

    !this.isPaginationEnabled()
      ? this.gridApi?.setGridOption("datasource", dataSourceAG)
      : this.gridApi?.setGridOption("rowData", data);
    if (this.gridApi && data.length === 0) {
      this.gridApi?.showNoRowsOverlay();
    } else {
      this.gridApi?.hideOverlay();
    }
  }

  hideActionColumn() {
    if (this.isMobile()) {
      this.gridApi.setColumnsVisible(["isSelected"], false);
    } else {
      this.gridApi.setColumnsVisible(["isSelected"], true);
    }
  }

  getContextMenuItems(params: any) {
    return getContextMenu(params);
  }

  async onCellClicked(event: any) {
    event.node.setSelected(false);
    if (this.isMobile()) {
      const params = {
        x: event.event.clientX,
        y: event.event.clientY,
        rowNode: event.node,
        column: event.column,
        value: event.value,
      };
      this.gridApi.showContextMenu(params);
    }
  }

  checkToggle(bookView: AccountView, checked: boolean) {
    if (checked) {
      this.checkedAccounts.push(bookView);
    } else {
      this.checkedAccounts = this.checkedAccounts.filter((account) => account.id !== bookView.id);
    }
  }

  async ngDoCheck() {
    if (this.hasScope === null || this.hasScope === undefined) {
      this.hasScope = await this.helperCommon.hasScope(scopeEnum.DATA_AGGREGATOR);
      this.runScopeBased();
    } else {
      this.runScopeBased();
    }
  }

  runScopeBased() {
    if (!this.hasScope) {
      this.accessLoader = false;
      this.hasAccess = false;
    } else {
      const userSubscription = this.subscriptionService.getUserSubscription();
      if (!userSubscription) {
        this.subscriptionService.initSubscription();
      }

      if (this.accessLoader && userSubscription?.metadata.id) {
        this.hasAccess = this.permission.hasAccess(userSubscription);
        this.accessLoader = false;
        if (this.openOptions) {
          this.openCreationOptionsModal();
          this.router.navigate(["/manage/account"]).then();
          this.openOptions = null;
        }
      }
    }
  }

  setLoading(loading: boolean) {
    this.loading = loading;
  }

  async premiumRequired() {
    if (!this.canAccessPremium) {
      this.messagingService.send("premiumRequired");
      return;
    }
  }

  async handleBasiqRedirect() {
    const shouldCallBasiq = await this.transactionBasiqImporter.isCallBasiqAfterRedirect();
    if (shouldCallBasiq) {
      await this.importBasiqIo();
    }
  }

  resetProgressbar() {
    this.label = null;
    this.progressBarPercent = 0;
    this.loading = false;
  }

  async saveBasiqInstitutions() {
    try {
      this.isInfo = false;
      /** TODO move this method to transaction-basiq.importer.ts*/
      await this.transactionBasiqImporter.saveInstitutions();
    } catch (e) {
      this.logService.error("saveBasiqInstitutions :");
      this.resetProgressbar();
      this.showGlobalError();
    }
  }

  async saveBasiqAccounts(): Promise<Book[]> {
    try {
      return await this.transactionBasiqImporter.saveAccounts();
    } catch (e) {
      this.logService.error("saveBasiqAccounts :");
      this.resetProgressbar();
      this.showGlobalError();
      return [];
    }
  }

  async saveBasiqTransactions(newAccounts: Book[], isAddAlignment = true) {
    try {
      for (const account of newAccounts) {
        const basiqConnector: Connector = await this.connectorService.getConnectorByOrigin(
          Origin.basiq
        );

        this.basiqProgressBar.setAccountLabel(account.name);
        await this.transactionBasiqImporter.saveBasiqTransactionsOfAccount(account, isAddAlignment);

        //TODO - @SinanT@Sinan - clean this up
        const institution = await this.institutionService.getInstitutionById(
          account.institutionLink.institutionId
        );
        const accountView = new AccountView(account, institution);
        const accountSyncService = new BasiqAccountSync(accountView, this.injector);
        const accountStatus = accountSyncService.getAccountStatus();
        accountStatus.isCompleted = true;
        accountStatus.point = {
          key: "synced",
          icon: SyncIcon.checkCircle,
          data: {
            type: "success",
            message: "Transactions synced successfully",
            actions: [],
            messageI18nKey: "transactionsSyncedSuccessfully",
          },
        };
        accountStatus.rawTransactions = [];
        accountStatus.accountId = account.id;
        accountStatus.accountView = null;
        accountStatus.lastSyncedAt = GlossDate.getCurrentGlossDate();
        if (!basiqConnector) {
          const connectorObj: any = {
            _id: crypto.randomUUID(),
            _name: Origin.basiq,
            _origin: Origin.basiq,
            _connectorType: Origin.basiq,
            _institution: [],
            _connectionInfo: {},
            accountStatus: [accountStatus],
          };
          const newConnector = new Connector(new ConnectorResponse(connectorObj));
          await this.connectorService.createConnector(newConnector);
        } else {
          const isAccountInConnector = basiqConnector.accountStatus.some(
            (acc) => acc.accountId === account.id
          );
          if (isAccountInConnector) {
            const index = basiqConnector.accountStatus.findIndex(
              (acc) => acc.accountId === account.id
            );
            basiqConnector.accountStatus[index] = accountStatus;
          } else {
            basiqConnector.accountStatus.push(accountStatus);
          }

          await this.connectorService.updateConnector(basiqConnector);
        }
      }
      this.resetProgressbar();
      await this.router.navigate(["/primary-dashboard"]);
    } catch (e) {
      this.logService.error("saveBasiqTransactions :");
      this.resetProgressbar();
      this.showGlobalError();
    }
  }

  showGlobalError() {
    this.globalService.showErrorMessage("error", "somethingWentWrong");
  }
  async importBasiqIo(isAddAlignment = true) {
    try {
      this.transactionBasiqImporter.startBasiqImport();
      this.loading = true;
      this.isInfo = true;
      await this.saveBasiqInstitutions();
      const newAccounts = await this.saveBasiqAccounts();
      await this.saveBasiqTransactions(newAccounts, isAddAlignment);
      this.loading = false;
      this.transactionBasiqImporter.endSuccessBasiqImport();
    } catch (e) {
      this.loading = false;
      this.transactionBasiqImporter.endFailureBasiqImport();
      this.showGlobalError();
      this.logService.error(e);
    }
  }

  addBalance(account: AccountView) {
    if (account.origin !== Origin.manual) {
      this.globalService.showWarningMessage("warning", "balanceForManualAccount");
      return;
    }
    const dialogRef = this.dialog.open(AccountBalanceComponent, {
      panelClass: "no-background-dialog",
      data: {
        accountView: account,
        actionSucceeded: this.actionSucceeded.bind(this),
        closeDialogue: this.closeBalanceModal.bind(this),
      },
      disableClose: true,
    });
    this.accountBalanceRef = dialogRef;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data) {
          this.existingAccounts = data;
        }
      });
  }

  addTransaction(account: AccountView) {
    const dialogRef = this.dialog.open(TransactionComponent, {
      panelClass: "no-background-dialog",
      data: {
        accountView: account,
        actionSucceeded: this.actionSucceeded.bind(this),
        closeDialogue: () => this.transactionDialogRef.close(),
      },
      disableClose: true,
    });
    this.transactionDialogRef = dialogRef;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data) {
          this.existingAccounts = data;
        }
      });
  }

  edit(account: AccountView, selectedInstitution?: Institution) {
    const dialogRef = this.dialog.open(AccountAddEditComponent, {
      panelClass: "no-background-dialog",
      data: {
        account,
        actionSucceeded: this.actionSucceeded.bind(this),
        delete: this.delete.bind(this),
        institution: selectedInstitution,
      },
      disableClose: true,
    });
    this.accountAddRef = dialogRef;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data) {
          this.existingAccounts = data;
        }
      });
  }

  closeBalanceModal() {
    this.accountBalanceRef.close();
  }

  async createConnector() {
    const glossDate = this.createGlossDate();

    const connector = {
      _id: crypto.randomUUID(),
      _name: "Basiq",
      _connectorType: this.Origin.basiq, // Todo:: detect the actual connectorType
      _institution: this.existingInstitutions, // Todo:: detect the actual institution
      _connectionInfo: {
        dates: [glossDate],
        connectionResult: false, // Todo:: The actual result
      },
    };
    const newConnector = new Connector(new ConnectorResponse(connector));
    try {
      const createdConnector = await this.connectorService.createConnector(newConnector);
      if (createdConnector instanceof Connector) {
        await this.actionSucceeded("createdSuccessfully");
      }
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }
  // todo put this method in a service ig
  private createGlossDate(): GlossDate {
    const glossDate = new GlossDate();
    glossDate.date = new Date();
    glossDate.dateString = glossDate.date.toISOString();
    glossDate.time = glossDate.date.toTimeString();
    glossDate.timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    return glossDate;
  }

  async deleteConnector() {
    try {
      const connector = this.existingConnector[0];
      const deleted = await this.connectorService.deleteConnector(connector);
      if (deleted) {
        await this.actionSucceeded("deletedSuccessfully");
      }
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
    }
  }
  /* TODO change this method's name.
   * this modal is to open last modal after user created an account
   * */
  openTransactionImportModal() {
    this.actionSucceeded("createdSuccessfully").then();
    this.accountAddRef.close();
    // TODO uncomment this code after implementing the transaction import again
    /*    const dialogRef = this.dialog.open(TransactionsImportPopupComponent, {
      width: "800px",
      data: {
        closeDialogue: this.closeTransactionsImportModal.bind(this),
      },
      disableClose: true,
    });
    this.transactionsImportPopupRef = dialogRef;
    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.destroy$))
      .subscribe((data) => {
        if (data) {
          this.existingAccounts = data;
        }
      });*/
  }

  async closeAccountAddModal() {
    this.accountAddRef.close();
    //this.existingAccounts = await this.dataRepositoryService.getAllBooks();
    this.booksView = await this.bookService.getBooksView();
    this.dataSource = new MatTableDataSource<AccountView>(this.booksView);
    this.dataSource.paginator = this.paginator;
    this.checkedAccounts = [];
  }
  addAccount(account: Book, selectedInstitution?: Institution) {
    const dialogRef = this.dialog.open(AccountAddEditComponent, {
      panelClass: "no-background-dialog",
      data: {
        actionSucceeded: this.openTransactionImportModal.bind(this),
        institution: selectedInstitution,
        accountCurrency: this.baseCurrency,
        closeDialogue: this.closeAccountAddModal.bind(this),
        openInstitutionSelectionModal: this.openInstitutionSelectionModal.bind(this),
      },
      disableClose: true,
    });
    this.accountAddRef = dialogRef;
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$));
  }

  createManually() {
    this.creationOptionsRef.close();
    this.openInstitutionSelectionModal();
  }

  openAddAccountModal(institution: Institution) {
    this.addAccount(null, institution);
  }
  closeRegionOptionDialogue() {
    this.regionOptionsRef.close();
  }
  createByLinking() {
    this.creationOptionsRef.close();
    const dialogRef = this.dialog.open(AccountRegionsComponent, {
      width: "800px",
      panelClass: "no-background-dialog",
      data: {
        closeDialogue: this.closeRegionOptionDialogue.bind(this),
        openCreationOptionModal: this.openCreationOptionsModal.bind(this),
        setLoading: this.setLoading.bind(this),
      },
      disableClose: true,
    });
    this.regionOptionsRef = dialogRef;
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$));
  }

  closeCreationOptionsDialogue() {
    this.creationOptionsRef.close();
  }

  closeInstitutionSelectionDialogue() {
    this.institutionsModalRef.close();
  }
  openInstitutionSelectionModal() {
    this.creationOptionsRef.close();
    const dialogRef = this.dialog.open(InstitutionSelectionComponent, {
      panelClass: "no-background-dialog",
      data: {
        closeDialogue: this.closeInstitutionSelectionDialogue.bind(this),
        openInstitutionDialogue: this.openInstitutionSelectionModal.bind(this),
        openCreationOptionsDialogue: this.openCreationOptionsModal.bind(this),
        openAccountAddModal: this.openAddAccountModal.bind(this),
      },
      disableClose: true,
    });
    this.institutionsModalRef = dialogRef;
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$));
  }

  openCreationOptionsModal() {
    const dialogRef = this.dialog.open(CreationOptionsComponent, {
      panelClass: "no-background-dialog",
      data: {
        createManually: this.createManually.bind(this),
        createByLinking: this.createByLinking.bind(this),
        closeDialogue: this.closeCreationOptionsDialogue.bind(this),
        isPremiumAccount: this.hasAccess,
      },
      disableClose: true,
    });
    this.creationOptionsRef = dialogRef;
    dialogRef.afterClosed().pipe(takeUntil(this.destroy$));
  }

  async deleteSelected() {
    if (this.checkedAccounts.length === 0) {
      this.globalService.showInfoMessage("", "noAccountSelected");
    } else {
      for (const account of this.checkedAccounts) {
        await this.delete(account.originalBook);
      }
    }
  }

  async deleteRight(accountView: AccountView) {
    await this.delete(accountView.originalBook);
  }

  isEntry(): boolean {
    return this.checkedAccounts.length === 1 && this.checkedAccounts[0].origin === Origin.manual;
  }

  isEdit(): boolean {
    return this.checkedAccounts.length === 1;
  }

  isDelete(): boolean {
    return this.checkedAccounts.length === 1;
  }

  addBalanceSelected() {
    if (this.checkedAccounts.length === 0) {
      this.globalService.showInfoMessage("", "noAccountSelected");
    } else {
      const firstManuelAccount = this.checkedAccounts.find(
        (account) => account.origin === Origin.manual
      );
      this.addBalance(firstManuelAccount);
    }
  }

  addEntryRight(accountView: AccountView) {
    if (accountView.origin === Origin.manual) {
      this.addBalance(accountView);
    } else {
      this.globalService.showInfoMessage("", "onlyForManuelAccount");
    }
  }

  editSelected() {
    if (this.checkedAccounts.length === 0) {
      this.globalService.showInfoMessage("", "noAccountSelected");
      return;
    }

    this.edit(this.checkedAccounts[0]);
  }

  editRight(accountView: AccountView) {
    this.edit(accountView);
  }

  async syncAllAccounts() {
    const isConnected = await this.basiqAuth.isThereConnection();
    if (isConnected) {
      this.loading = true;
      const jobs = await this.transactionBasiqImporter.refreshConnections();
      await this.basiqAuth.areJobsResolved(jobs);
      await this.importBasiqIo(false);

      this.loading = false;
    } else {
      this.loading = false;
      this.globalService.showWarningMessage("warning", "noInstitutionConnected");
    }
  }

  async testConnector() {
    //test connector
    await this.connectorService.getAllConnectors();
  }

  async delete(book: Book) {
    try {
      const transactions = await this.bookService.getTransactionsByAccount(book.id);
      this.sourceBook = await this.bookService.getBookLink(book.name);

      let confirmed;
      if (transactions.length === 0) {
        confirmed = await this.confirmationDialogService.confirmFor(
          ConfirmationEnum.deletingAccount
        );
        if (confirmed) {
          this.spinnerLoader = true;
          await this.deleteBook(book);
        }
      } else {
        const deletableTransactions = transactions.filter(
          (transaction) => transaction.linkedTo.length === 0
        );

        if (deletableTransactions.length === transactions.length) {
          const message = this.i18nService.t(
            "deleteAccountConfirmMessageAllTransactions",
            transactions.length.toString()
          );
          confirmed = await this.confirmationDialogService.confirmFor(
            ConfirmationEnum.deletingAccountWithGeneratedMessage,
            { messageReplacement: message }
          );

          if (confirmed) {
            this.spinnerLoader = true;
            await this.deleteTransactions(transactions, true, book);
            await this.deleteSourceTransaction(book.id);
            this.spinnerLoader = false;
          }
        } else {
          const message = this.i18nService.t(
            "deleteAccountConfirmMessageSomeTransactions",
            deletableTransactions.length.toString(),
            transactions.length.toString()
          );
          confirmed = await this.confirmationDialogService.confirmFor(
            ConfirmationEnum.deletingAccountWithGeneratedMessage,
            { messageReplacement: message }
          );

          if (confirmed) {
            this.spinnerLoader = true;
            await this.deleteTransactions(transactions, false);
          }
        }
      }
    } catch (e) {
      this.globalService.showErrorMessage("errorOccurred", e);
      this.spinnerLoader = false;
    } finally {
      this.loading = false;
      this.spinnerLoader = false;
    }
  }

  private async deleteTransactions(
    deletableTransactions: Transaction[],
    canDeleteBook: boolean,
    book?: Book
  ) {
    await this.transactionService.bulkDeleteTransactions(deletableTransactions);

    if (canDeleteBook) {
      await this.deleteBook(book);
    }
  }

  async deleteConnectors() {
    const connectors = this.existingConnector;
    await this.connectorService.bulkDeleteConnectors(connectors);
  }

  private async deleteBook(book: Book) {
    const deleted = await this.bookService.delete(book, false);
    if (deleted) {
      // sourcebook only created for basiq accounts and imported data (csv, pdf)
      if (this.sourceBook) {
        await this.deleteSourceBook(this.sourceBook);
      }
      const basiqConnector = await this.connectorService.getConnectorByOrigin(Origin.basiq);
      if (basiqConnector) {
        basiqConnector.accountStatus = basiqConnector.accountStatus.filter(
          (accountStatus) => accountStatus.accountId !== book.id
        );
        await this.connectorService.updateConnector(basiqConnector);
      }
      await this.actionSucceeded("deletedSuccessfully");
    }
  }

  private async deleteSourceBook(sourceBook: SourceBook) {
    return await this.bookService.deleteBookLink(sourceBook);
  }

  private async deleteSourceTransaction(bookId: string) {
    await this.sourceTransactionService.bulkDelete(this.sourceTransactions);
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  async actionSucceeded(actionKey: string) {
    this.existingAccounts = await this.bookService.getAll();
    if (this.existingAccounts.length === 0) {
      this.glossMenuService.setShouldRunWizard(true, false);
    }
    this.booksView = await this.bookService.getBooksView();
    this.dataSource = new MatTableDataSource<AccountView>(this.booksView);
    this.loadGridData(this.booksView);
    this.checkedAccounts = [];
    this.dataSource.paginator = this.paginator;
    this.accountAddRef?.close(this.existingAccounts);
    this.globalService.showSuccessMessage("succeeded", actionKey);
  }

  isMobile() {
    return this.deviceService.isMobile();
  }

  isPaginationEnabled() {
    return this.paginationHelper.isPaginationEnabled();
  }
}
