import { Injectable } from "@angular/core";
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";

@Injectable({
  providedIn: "root",
})
export class InitialRedirectGuard {
  constructor(private router: Router) {}

  canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    const initialRoute = "/primary-dashboard";

    if (state.url !== initialRoute && !state.url.includes("jobId")) {
      this.router.navigate([initialRoute]).then((r) => r);
      return false;
    }
    return true;
  }
}
